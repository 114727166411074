<template>

	<div>

		<!-- Filters -->
		<courses-list-filters
			:options="options"
			:course-types-filter.sync="courseTypesFilter"
        />

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0">

			<div class="m-2">

				<!-- Table Top -->
				<b-row>

					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>

					<!-- Search -->
					<b-col
						cols="12"
						md="6"
						class="ml-auto">
						<div class="d-flex">
							<b-form-input
								v-model="searchQuery"
								class="d-inline-block mr-1"
								placeholder="Buscar servicio ..."
								trim
							/>
						</div>
					</b-col>

				</b-row>

			</div>

			<b-table
				ref="refCourseListTable"
				class="position-relative table-courses"
				:style="totalCourses >= 1 && totalCourses <= 3 ? 'height: 280px' : 'height: inherit'"
				:items="fetchCourses"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>

				<template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mb-1"></b-spinner>
                        <div><b>CARGANDO</b></div>
                    </div>
                </template>

				<template #head(course_type_name)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(minimum_score)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(inscriptions_count)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(name)="data">
					<b-link
						:to="{ name: 'contractors-courses-edit', params: { slug: data.item.slug } }"
						class="font-weight-bold d-block text-nowrap"
					>
						{{ data.item.name }}
					</b-link>
				</template>

				<template #cell(course_type_name)="data">
					<span class="d-block text-nowrap">
						<b-badge :variant="data.item.course_type_color">
							{{ data.item.course_type_name }}
						</b-badge>
					</span>
                </template>

				<template #cell(minimum_score)="data">
					<span class="d-block text-nowrap">
						{{ parseFloat(data.item.minimum_score) }}
					</span>
				</template>

				<template #cell(amount)="data">
					<span class="font-weight-bold d-block text-nowrap">
						{{ `S/. ${parseFloat(data.item.amount).toFixed(2)}` }}
                    </span>
				</template>

				<template #cell(createdAt)="data">
					<span class="d-block text-nowrap">
                        {{ moment(data.item.createdAt).format('DD/MM/YYYY HH:mm:ss') }}
                    </span>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL">

						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item :to="{ name: 'contractors-courses-edit', params: { slug: data.item.slug } }">
							<feather-icon icon="EyeIcon" />
							<span class="align-middle ml-50">Ver detalles</span>
						</b-dropdown-item>

						<b-dropdown-item :to="{ name: 'contractors-courses-edit', params: { slug: data.item.slug }, query: { tab: 'inscriptions' } }">
							<feather-icon icon="UsersIcon" />
							<span class="align-middle ml-50">Inscritos</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>

			</b-table>

			<div class="mx-2 mb-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end">

						<b-pagination
							v-model="currentPage"
							:total-rows="totalCourses"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item">

							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

	</div>

</template>

<script>

	import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
	import CoursesListFilters from './CoursesListFilters.vue';
	import courseStoreModule from '../courseStoreModule';
	import useCoursesList from './useCoursesList';
	import Ripple from 'vue-ripple-directive';
	import vSelect from 'vue-select';
	import store from '@/store';
	import moment from 'moment';
	import axios from '@axios';

	export default {
		components: {
			CoursesListFilters,

			vSelect
		},
		directives: {
            Ripple
        },
		data() {
            return {
                moment
            }
        },
		setup() {

			// REFS
			const options = ref({});

			const COURSE_APP_STORE_MODULE_NAME = 'app-course';

			// REGISTER MODULE
			if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.registerModule(COURSE_APP_STORE_MODULE_NAME, courseStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME);
			});

			// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( async () => {
                await getSelectFilters();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/contractors/courses/filters');
                options.value = data.data;
            };

			const {
				fetchCourses,
				tableColumns,
				perPage,
				currentPage,
				totalCourses,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refCourseListTable,
				refetchData,

				// EXTRA FILTERS
                courseTypesFilter
			} = useCoursesList();

			return {
				fetchCourses,
				tableColumns,
				perPage,
				currentPage,
				totalCourses,
				dataMeta,
				perPageOptions,
				searchQuery,
				sortBy,
				isSortDirDesc,
				refCourseListTable,
				refetchData,

				// REFS
				options,

				// EXTRA FILTERS
                courseTypesFilter
			}
		}
	}

</script>

<style lang="scss" scoped>

	.media {
        align-items: center;
    }

	.per-page-selector {
		width: 90px;
	}

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>
